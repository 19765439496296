import React from 'react'
import { Link } from 'gatsby'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import styles from './tos.module.scss'

const PicCollageEdu = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <main className={styles.root}>
        <h2>ACCEPTANCE OF TERMS</h2>
        <p>
          PicCollage EDU provides the PicCollage EDU Services (defined below) to
          you subject to the following Terms of Service ("TOS"), which may be
          updated by us from time to time without notice to you. You can review
          the most current version of the TOS at any time at:
          <Link to="/tos/piccollageedu">
            http://cardinalblue.com/tos/piccollageedu
          </Link>
          . By accessing and using the PicCollage EDU Services, you accept and
          agree to be bound by the terms and provision of the TOS. In addition,
          when using particular PicCollage EDU owned or operated services, you
          and PicCollage EDU shall be subject to any posted guidelines or rules
          applicable to such service, which may be posted and modified from time
          to time. All such guidelines or rules are hereby incorporated by
          reference into the TOS.
        </p>

        <h2>DESCRIPTION OF PICCOLLAGE EDU SERVICE</h2>
        <p>
          PicCollage EDU provides users with a way to create photo collages
          using photos from their devices and the internet search, as well as
          stickers, text, GIFs, videos and other media.
        </p>
        <p>
          You also understand and agree that the PicCollage EDU Services may
          include certain communications from PicCollage EDU, such as service
          announcements and administrative messages, and that these
          communications are considered part of PicCollage EDU. You will be able
          to opt out of receiving them by disabling push notifications, but you
          can view them as announcements inside the app itself. You understand
          and agree that the PicCollage Services is provided "AS-IS" and that
          PicCollage EDU assumes no responsibility for the timeliness, deletion,
          mis-delivery or failure to store any user communications or
          personalization settings. You are responsible for obtaining access to
          the PicCollage EDU Services, and that access may involve third-party
          fees (such as Internet service provider or airtime charges). In
          addition, you must provide and are responsible for all equipment
          necessary to access the PicCollage EDU Services.
        </p>

        <h2>USER CONDUCT</h2>
        <p>
          You understand that all information, data, text, software, music,
          sound, photographs, graphics, video, messages, tags, or other
          materials ("Content"), whether publicly posted or privately
          transmitted, are the sole responsibility of the person from whom such
          Content originated. This means that you, and not PicCollage EDU, are
          entirely responsible for all Content that you create, save, email,
          transmit or otherwise make available via the PicCollage EDU Services.
          PicCollage EDU does not control the Content posted via the PicCollage
          Services and, as such, does not guarantee the accuracy, integrity or
          quality of such Content. You understand that by using the PicCollage
          EDU Services, you may be exposed to Content that is offensive,
          indecent or objectionable. Under no circumstances will PicCollage EDU
          be liable in any way for any Content, including, but not limited to,
          any errors or omissions in any Content, or any loss or damage of any
          kind incurred as a result of the use of any Content posted, emailed,
          transmitted or otherwise made available via the PicCollage EDU
          Services.
        </p>

        <p>
          You agree that under the use of PicCollage EDU Services:
          <ul>
            <li>
              There is no age limitation for using the PicCollage EDU
              application.
            </li>
            <li>
              You may not use the PicCollage EDU Services for any illegal or
              unauthorized purpose. International users agree to comply with all
              local laws regarding online conduct and acceptable content.
            </li>
            <li>
              You are solely responsible for your conduct and any data, text,
              information, screen names, graphics, photos, profiles, audio and
              video clips, links ("Content") that you create and save using the
              PicCollage EDU Services.
            </li>
            <li>
              You must not modify, adapt or hack PicCollage EDU or modify
              another website so as to falsely imply that it is associated with
              PicCollage EDU.
            </li>
            <li>
              You must not access the PicCollage EDU private API by any other
              means other than the PicCollage EDU application itself.
            </li>
            <li>
              You must not use web URLs in your name without prior written
              consent from PicCollage EDU.
            </li>
            <li>
              You must not transmit any worms or viruses or any code of a
              destructive nature.
            </li>
            <li>
              You must not, in the use of PicCollage EDU, violate any laws in
              your jurisdiction (including but not limited to copyright laws).
            </li>
            <li>
              You must not email, transmit or otherwise make available any
              material that contains software viruses or any other computer
              code, files or programs designed to interrupt, destroy or limit
              the functionality of any computer software or hardware or
              telecommunications equipment.
            </li>
            <li>
              You must not interfere with or disrupt the PicCollage EDU Services
              or servers or networks connected to the PicCollage EDU Services,
              or disobey any requirements, procedures, policies or regulations
              of networks connected to the PicCollage EDU Services, including
              using any device, software or routine to bypass our robot
              exclusion headers.
            </li>
          </ul>
        </p>
        <p>
          In consideration of your use of the PicCollage EDU Services, you
          represent that you are of legal age to form a binding contract and are
          not a person barred from receiving the PicCollage EDU Services under
          the laws of the United States or other applicable jurisdiction.
        </p>

        <h2>GENERAL CONDITIONS</h2>
        <p>
          <ul>
            <li>
              We reserve the right to modify or terminate the PicCollage EDU
              services for any reason, without notice at any time.
            </li>
            <li>
              We reserve the right to alter these Terms of Service at any time.
              If the alterations constitute a material change to the Terms of
              Service, we will notify you via internet mail according to the
              preference expressed on your account. What constitutes a "material
              change" will be determined at our sole discretion, in good faith
              and using common sense and reasonable judgment.
            </li>
            <li>
              We reserve the right to refuse service to anyone for any reason at
              any time. We may, but have no obligation to, remove Content and
              accounts containing Content that we determine in our sole
              discretion are unlawful, offensive, threatening, libelous,
              defamatory, obscene or otherwise objectionable or violates any
              party's intellectual property or these Terms of Services.
            </li>
            <li>
              We will honor parents’ requests for deletion of student data.
              Requests must be made by contacting PicCollage EDU at{' '}
              <a href="mailto:info@cardinalblue.com">info@cardinalblue.com</a>.
            </li>
            <li>
              We acknowledge that student data is not used for commercial
              purposes.
            </li>
            <li>
              We acknowledge that data collected through PicCollage EDU is
              retained only for education purposes.
            </li>
          </ul>
        </p>

        <h2>PROPRIETARY RIGHTS IN CONTENT ON PICCOLLAGE EDU</h2>
        <p>
          <ul>
            <li>
              PicCollage EDU does NOT claim ANY ownership rights in the text,
              files, images, photos, video, sounds, musical works, works of
              authorship, applications, or any other materials (collectively,
              "Content") that you create using the PicCollage EDU Services.
            </li>
            <li>
              You represent and warrant that: (i) you own the Content created by
              you on or through the PicCollage EDU Services or otherwise have
              the right to grant the license set forth in this section, (ii) the
              creation and use of your Content on or through the PicCollage EDU
              Services does not violate the privacy rights, publicity rights,
              copyrights, contract rights, intellectual property rights or any
              other rights of any person, and (iii) the posting of your Content
              on the Site does not result in a breach of contract between you
              and a third party. You agree to pay for all royalties, fees, and
              any other monies owing any person by reason of Content you create
              on or through the PicCollage EDU Services.
            </li>
            <li>
              The PicCollage EDU Services contain Content of PicCollage EDU
              ("PicCollage EDU Content"). PicCollage EDU Content is protected by
              copyright, trademark, patent, trade secret and other laws, and
              Cardinal Blue owns and retains all rights in the PicCollage EDU
              Content and the PicCollage EDU Services.
            </li>
            <li>
              The PicCollage EDU Services contain Content of Users and other
              PicCollage EDU licensors. Except as provided within this
              Agreement, you may not copy, modify, translate, publish,
              broadcast, transmit, distribute, perform, display, or sell any
              Content appearing on or through the PicCollage EDU Services.
            </li>
            <li>
              PicCollage EDU performs technical functions necessary to offer the
              PicCollage EDU Services, including but not limited to transcoding
              and/or reformatting Content to allow its use throughout the
              PicCollage EDU Services.
            </li>
            <li>
              Although the Site and other PicCollage EDU Services are normally
              available, there will be occasions when the Site or other
              PicCollage EDU Services will be interrupted for scheduled
              maintenance or upgrades, for emergency repairs, or due to failure
              of telecommunications links and equipment that are beyond the
              control of PicCollage EDU. Also, although PicCollage EDU will
              normally only delete Content that violates this Agreement,
              PicCollage EDU reserves the right to delete any Content for any
              reason, without prior notice. Deleted content may be stored by
              PicCollage EDU in order to comply with certain legal obligations
              and is not retrievable without a valid court order. Consequently,
              PicCollage EDU encourages you to maintain your own backup of your
              Content. In other words, PicCollage EDU is not a backup service.
              PicCollage EDU will not be liable to you for any modification,
              suspension, or discontinuation of the PicCollage Services, or the
              loss of any Content.
            </li>
          </ul>
        </p>
        <p>
          With respect to Content you submit or make available for inclusion on
          publicly accessible areas of PicCollage EDU, the license to use,
          distribute, reproduce, modify, adapt, publicly perform and publicly
          display such Content on the PicCollage EDU Services solely for the
          purposes of providing and promoting the specific PicCollage to which
          such Content was submitted or made available. This license exists only
          for as long as you elect to continue to include such Content on the
          PicCollage Services and will terminate at the time you remove or
          PicCollage EDU removes such Content from the PicCollage EDU Services.
        </p>

        <h2>CONTRIBUTIONS TO PICCOLLAGE EDU</h2>
        <p>
          By submitting ideas, suggestions, documents, and/or proposals
          ("Contributions") to PicCollage EDU through its suggestion or feedback
          webpages, you acknowledge and agree that: (a) your Contributions do
          not contain confidential or proprietary information; (b) PicCollage
          EDU is not under any obligation of confidentiality, express or
          implied, with respect to the Contributions; (c) PicCollage EDU may
          have something similar to the Contributions already under
          consideration or in development; (d) your Contributions automatically
          become the property of PicCollage EDU without any obligation of
          PicCollage EDU to you; and (e) you are not entitled to any
          compensation or reimbursement of any kind from PicCollage EDU under
          any circumstances.
        </p>

        <h2>INDEMNITY</h2>
        <p>
          As permitted by law, you agree to indemnify and hold PicCollage EDU
          and its subsidiaries, affiliates, officers, agents, employees,
          partners and licensors harmless from any claim or demand, including
          reasonable attorneys'; fees, made by any third party due to or arising
          out of Content you create, transmit, modify or otherwise make
          available through the PicCollage EDU Services, your use of the
          PicCollage EDU Services, your connection to the PicCollage EDU
          Services, or your violation of any rights of another Terms of Service.
        </p>

        <h2>NO COMMERCIAL REUSE OF PICCOLLAGE EDU SERVICES</h2>
        <p>
          You agree not to reproduce, duplicate, copy, sell, trade, resell or
          exploit for any commercial purposes, any portion or use of, or access
          to, the PicCollage EDU Services.
        </p>

        <h2>MODIFICATIONS TO PICCOLLAGE EDU SERVICES</h2>
        <p>
          PicCollage EDU reserves the right at any time and from time to time to
          modify or discontinue, temporarily or permanently, the PicCollage EDU
          Services (or any part thereof) with or without notice. You agree that
          PicCollage EDU shall not be liable to you or to any third party for
          any modification, suspension or discontinuance of the PicCollage EDU
          Services (or any part thereof).
        </p>

        <h2>TERMINATION</h2>
        <p>
          You agree that PicCollage EDU may, without prior notice, immediately
          terminate, limit your access to or suspend your PicCollage EDU
          account, any associated email address, and access to the PicCollage
          EDU Services. Cause for such termination, limitation of access or
          suspension shall include, but not be limited to, (a) breaches or
          violations of the TOS or other incorporated agreements or guidelines,
          (b)requests by law enforcement or other government agencies, (c)
          discontinuance or material modification to the PicCollage EDU Services
          (or any part thereof), (d) unexpected technical or security issues or
          problems, (e) extended periods of inactivity, (f) engagement by you in
          fraudulent or illegal activities, and/or (g) nonpayment of any fees
          owed by you in connection with the PicCollage EDU Services. Further,
          you agree that all terminations, limitations of access and suspensions
          for cause shall be made in PicCollage's Kids sole discretion and that
          PicCollage EDU shall not be liable to you or any third party for any
          termination of your account, any associated email address, or access
          to the PicCollage EDU Services.
        </p>

        <h2>GDPR COMPLIANCE</h2>
        <p>
          We have implemented our GDPR compliance strategy to make sure the
          rights of our users in the European Union are protected. We are
          committed to giving you transparent information, communication and
          modalities of the information we collect and how we process it and for
          the exercise of your rights under GDPR.
        </p>

        <p>
          Below are the initiatives we have committed to in order to satisfy
          GDPR requirements that apply to our European users:
        </p>

        <h3>User Consent</h3>
        <p>
          Users that reside in the European Union (E.U.) must grant us consent
          before using PicCollage starting from May 25, 2018. By giving us
          consent, you agree to how we collect and process your data.
        </p>

        <ol class="decimal">
          <li>
            Please note you can withdraw your consent at any time on the app’s
            Data Privacy page, accessible through the Settings page. If you have
            created a social network account within PicCollage, you have to go
            to our webpage to edit your account preferences in order to
            deactivate your account.
          </li>

          <li>
            You must be at least 13 years old to create a social network account
            within the PicCollage app, but you can be younger to use PicCollage.
          </li>

          <li>
            We only process data for users over the age of 16 unless explicit
            consent has been given by a parental guardian to process the
            information of a data subject under 16 years of age.
          </li>
        </ol>

        <h3>Data Subject Rights</h3>
        <p>
          As the data subject, you have the right to either give consent or
          decline consent to our terms and policies. Please note by declining
          consent, you will not be able to use PicCollage at the moment. The
          following are the rights you can exercise regarding your information
          under GDPR:
        </p>

        <ol class="decimal">
          <li>
            The right of access
            <br />
            As the data subject, you have the right to know how your data is
            being processed and you can also request to access your data.
          </li>

          <li>
            The right to rectification
            <br />
            As the data subject, you can obtain rectification of inaccurate
            personal data from us in a timely manner.
          </li>

          <li>
            The right to erasure
            <br />
            As the data subject, you have the right to obtain the erasure of
            your data under the following circumstances:
            <ul>
              <li>
                the personal data are no longer necessary (for example, in the
                case that a user deletes their account)
              </li>

              <li>when you withdraw consent to our terms and policies</li>

              <li>when your personal data have been unlawfully processed</li>
            </ul>
          </li>

          <li>
            The right to restriction of processing
            <br />
            You have the right to obtain restriction of our processing of your
            data when the accuracy of the data is contested by you, the data
            subject.
          </li>

          <li>
            The right to be informed
            <br />
            You have the right to be informed by PicCollage on the changes we
            make regarding the collection and processing of your information.
          </li>

          <li>
            The right to object
            <br />
            As the data subject, you have the right to ask us to stop processing
            your data.
          </li>

          <li>
            The right not to be subject to automated individual decision-making,
            including profiling.
          </li>
        </ol>

        <h2>DISCLAIMER OF WARRANTIES</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
          <ul>
            <li>
              YOUR USE OF THE PICCOLLAGE EDU SERVICES AND SOFTWARE ARE AT YOUR
              SOLE RISK. THE PICCOLLAGE EDU SERVICES AND SOFTWARE ARE PROVIDED
              ON AN "AS IS" AND "AS AVAILABLE" BASIS. PICCOLLAGE AND ITS
              SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS
              AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
              IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            </li>
            <li>
              PICCOLLAGE EDU AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS,
              EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT
              (i) THE PICCOLLAGE SERVICES OR SOFTWARE WILL MEET YOUR
              REQUIREMENTS; (ii) THE PICCOLLAGE EDU SERVICES OR SOFTWARE WILL BE
              UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THE RESULTS
              THAT MAY BE OBTAINED FROM THE USE OF THE PICCOLLAGE EDU SERVICES
              OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF ANY
              PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR
              OBTAINED BY YOU THROUGH THE PICCOLLAGE EDU SERVICES OR SOFTWARE
              WILL MEET YOUR EXPECTATIONS; AND (v) ANY ERRORS IN THE SOFTWARE
              WILL BE CORRECTED.
            </li>
            <li>
              ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
              THE PICCOLLAGE EDU SERVICES OR SOFTWARE IS ACCESSED AT YOUR OWN
              DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND
              HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT
              TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD
              OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD
              OF ANY SUCH MATERIAL.
            </li>
            <li>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM PICCOLLAGE EDU OR THROUGH OR FROM THE PICCOLLAGE EDU SERVICES
              OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
              TOS.
            </li>
            <li>
              A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN
              EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER
              SCREEN OR WHILE USING THE PICCOLLAGE EDU SERVICE. CERTAIN
              CONDITIONS MAY INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS
              EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY.
              IF YOU, OR ANYONE IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION,
              CONSULT YOUR PHYSICIAN PRIOR TO USING THE PICCOLLAGE EDU SERVICE.
              IMMEDIATELY DISCONTINUE USE OF THE PICCOLLAGE EDU SERVICES AND
              CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING
              SYMPTOMS WHILE USING THE PICCOLLAGE EDU SERVICE: DIZZINESS,
              ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
              DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
            </li>
          </ul>
        </p>

        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT PICCOLLAGE EDU AND ITS
          SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
          LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING,
          BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA
          OR OTHER INTANGIBLE LOSSES (EVEN IF PICCOLLAGE EDU HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (a) THE USE OR THE
          INABILITY TO USE THE PICCOLLAGE EDU SERVICE; (b) THE COST OF
          PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES; (c) UNAUTHORIZED ACCESS
          TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (d) STATEMENTS OR
          CONDUCT OF ANY THIRD PARTY ON THE PICCOLLAGE EDU SERVICE; OR (e) ANY
          OTHER MATTER RELATING TO THE PICCOLLAGE EDU SERVICE.
        </p>

        <h2>NO THIRD-PARTY BENEFICIARIES</h2>
        <p>
          You agree that there shall be no third-party beneficiaries to this
          agreement.
        </p>

        <h2>NOTICE</h2>
        <p>
          PicCollage EDU may provide you with notices, including those regarding
          changes to the TOS, including by but not limited to email, regular
          mail, postings on the PicCollage EDU Services, or other reasonable
          means now known or hereafter developed. Such notices may not be
          received if you violate this TOS by accessing the PicCollage EDU
          Services in an unauthorized manner. Your agreement to this TOS
          constitutes your agreement that you are deemed to have received any
          and all notices that would have been delivered had you accessed the
          PicCollage EDU Services in an authorized manner.
        </p>

        <h2>TRADEMARK INFORMATION</h2>
        <p>
          You agree that all of PicCollage EDU’ trademarks, trade names, service
          marks and other PicCollage EDU logos and brand features, and product
          and service names are trademarks and the property of Cardinal Blue
          Software, Inc. (the "PicCollage Marks"). Without prior permission, you
          agree not to display or use in any manner the PicCollage Marks.
        </p>

        <h2>
          NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT OR INTELLECTUAL
          PROPERTY INFRINGEMENT
        </h2>
        <p>
          PicCollage EDU respects the intellectual property of others, and we
          ask our users to do the same. PicCollage EDU may, in appropriate
          circumstances and at its discretion, disable and/or terminate the
          accounts of users who may be repeat infringers.
        </p>

        <h2>VIOLATIONS</h2>
        <p>Please report any violations of the TOS to us.</p>

        <br />
        <p>Last updated: January 2018</p>
      </main>
    </Layout>
  )
}

export default PicCollageEdu
